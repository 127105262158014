<template>

  <div style="background-color: var(--white);">

    <div v-for="(c,index) in countries" v-bind:key="index">

      <a
          style="color: var(--black);"
          @click="filterByCountry(c.country_code)">
        <div class="trend-item">
          <div class="trend-text1">
            <lazy-image class="lozad" v-if="c.country_code.length > 0" v-bind:alt="c.country_name" v-bind:src="getCountryFlag(c.country_code)" style="width: 15px" />
            {{ c.country_name }}</div>
          <div class="trend-text1"> {{ c.games }} <span style="position: relative;top: -1px;">
            <lazy-image class="lozad" style="height: 1em;" v-bind:src="getURL('/assets/icons/right.svg')" /></span></div>
        </div>
      </a>

      <div class="mr-1a ml-1a" style="background-color: var(--gray); height: .1em;"></div>

    </div>

    <router-link style="color: var(--black);" to="/countries">
      <div class="trend-item">
        <div class="trend-text1"><span>
          <lazy-image class="lozad" style="height: 1em;" v-bind:src="getURL('/assets/icons/world.svg')" /></span> A-Z Countries</div>
        <div class="trend-text1"><span style="position: relative;top: -1px;">
          <lazy-image style="height: 1em;" v-bind:src="getURL('/assets/icons/right.svg')" />
        </span></div>
      </div>
    </router-link>

  </div>

</template>

<script>
export default {
  name: "Country",
  mounted: function() {

    //this.$store.dispatch("setCurrentPage","country");

  },
  methods: {

    filterByCountry: function (country_code) {

      var default_payload = this.$store.state.default_games_filter;
      default_payload.country_code = country_code;
      this.$store.dispatch("setGamesFilter",default_payload);
      this.$store.dispatch("setCurrentSubPage","highlights");

    },

  },
  computed: {

    leagues: function () {

      return this.$store.state.leagues;

    },
    countries: function () {

      return  this.$store.state.countries;

    },
    current_page: function () {

      return this.$store.state.current_page;
    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    }
  }
}
</script>

<style scoped>

</style>